import React from "react"
// import { Link } from "gatsby"

import Layout from "iid/components/layout"
// import Image from "../components/image"
import SEO from "iid/components/seo"

const BlogPage = () => (
  <Layout>
    <SEO title="IID Blog: Random Musings" />
  </Layout>
)

export default BlogPage
